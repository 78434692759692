<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入姓名" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增用户</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="userName" label="姓名"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="" label="性别">
            <template slot-scope="scope">
              <span>{{ comSex(scope.row.userSex) }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="departmentName" label="部门"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="identityCard" label="身份证"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="birthDate" label="出生日期"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="nativePlace" label="籍贯"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="160" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="userName">
              <el-input v-model="ruleForm.userName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="departmentId">
              <el-cascader class="cascader" v-model="ruleForm.departmentId" :options="departmentList" :props="{ expandTrigger: 'hover', value: 'departmentId', checkStrictly: true, label: 'departmentName' }" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
            <el-form-item label="出生日期" prop="birthDate">
              <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.birthDate" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="分配角色" prop="roleIds">
              <el-select v-model="ruleForm.roleIds" multiple collapse-tags placeholder="请选择角色">
                <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登录账号" prop="userAccount">
              <el-input v-model="ruleForm.userAccount" placeholder="请输入登录账号"></el-input>
              <!-- <el-input v-if="isAdd" v-model="ruleForm.userAccount" placeholder="请输入登录账号"></el-input>
              <div v-else class="disabled_user_account">{{ ruleForm.userAccount }}</div> -->
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="性别" prop="userSex">
              <el-select v-model="ruleForm.userSex" placeholder="请选择性别">
                <el-option label="女" value="0"></el-option>
                <el-option label="男" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证" prop="identityCard">
              <el-input v-model="ruleForm.identityCard" placeholder="请输入身份证"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="userPhone">
              <el-input v-model="ruleForm.userPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="籍贯" prop="nativePlace">
              <el-input v-model="ruleForm.nativePlace" placeholder="请输入籍贯"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="userPassword">
              <el-input v-model="ruleForm.userPassword" placeholder="请输入登录密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
function createInitRuleForm() {
  return {
    userName: "",
    userSex: "",
    birthDate: "",
    departmentId: "",
    identityCard: "",
    userPhone: "",
    nativePlace: "",
    userAccount: "",
    userPassword: "",
    roleIds: [],
  }
}
export default {
  data() {
    // 正则表达式验证身份证的格式
    var validateIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("身份证不能为空"))
      }
      const idCardRegex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X)$/
      if (!idCardRegex.test(value)) {
        callback(new Error("身份证格式不正确"))
      } else {
        callback()
      }
    }
    var checkTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("联系电话不能为空"))
      }
      const telRegex = /^(?:(?:\+|00)86)?1\d{10}$/
      if (!telRegex.test(value)) {
        callback(new Error("联系电话格式不正确"))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      submitLoding: false,
      search: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: createInitRuleForm(),
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "submit" }],
        userSex: [{ required: true, message: "请选择性别", trigger: "submit" }],
        birthDate: [{ required: true, message: "请选择日期", trigger: "submit" }],
        departmentId: [{ required: true, message: "请选择部门", trigger: "submit" }],
        roleIds: [{ required: true, message: "请选择角色", trigger: "submit" }],
        nativePlace: [{ required: true, message: "请输入籍贯", trigger: "submit" }],
        userAccount: [{ required: true, message: "请输入登录账号", trigger: "submit" }],
        userPhone: [{ required: true, validator: checkTel, trigger: "submit" }],
        identityCard: [{ required: true, validator: validateIdCard, trigger: "submit" }],
        userPassword: [{ required: true, message: "请输入登录密码", trigger: "submit" }],
      },
      departmentList: [],
      roleList: [],
    }
  },
  created() {
    this.getSysUserList()
    this.getSysDepartmentList()
    this.getSysRoleList()
  },
  computed: {
    comSex() {
      return function (sex) {
        return sex == 0 ? "女" : sex == 1 ? "男" : "未知"
      }
    },
  },
  methods: {
    // 用户列表
    getSysUserList() {
      this.loading = true
      this.$axios
        .get(this.$api.getSysUserList, {
          params: {
            userName: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 部门列表
    getSysDepartmentList() {
      this.$axios
        .get(this.$api.getSysDepartmentList, {
          params: {
            page: 999,
          },
        })
        .then(res => {
          this.departmentList = res.data.result
        })
        .catch(() => {
          // 异常处理
        })
    },
    // 角色列表
    getSysRoleList() {
      this.$axios
        .get(this.$api.getSysRoleList, {
          params: {
            page: 1,
            pageSize: 999,
          },
        })
        .then(res => {
          let list = res.data.result.list.filter(e => e.status == "1")
          this.roleList = list
        })
        .catch(() => {
          // 异常处理
        })
    },
    // 搜索
    onSearch() {
      this.currentPage = 1
      this.getSysUserList()
    },
    // 新增（对话框）
    handleAdd() {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 修改（对话框）
    handleEdit(index, row) {
      this.isAdd = false
      this.$axios
        .get(`${this.$api.getSysUserById}/${row.userId}`)
        .then(res => {
          if (res.data.code == 100) {
            this.ruleForm = res.data.result
            this.dialogVisible = true
          } else {
            this.dialogVisible = false
          }
        })
        .catch(() => {
          this.dialogVisible = false
        })
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(`${this.$api.deleteSysUser}/${row.userId}`)
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
              }
              this.getSysUserList()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getSysUserList()
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getSysUserList()
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
      this.resetForm()
    },
    // 选择部门
    changeCascader(arr) {
      // 截取arr最后一个元素
      this.ruleForm.departmentId = arr[arr.length - 1]
    },
    // 提交表单
    submitForm(formName) {
      let params = Object.assign({}, this.ruleForm)
      let api = ""
      let methods = ""
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isAdd) {
            api = this.$api.addSysUser
            methods = "post"
            params.userId = null
          } else {
            api = this.$api.updateSysUser
            methods = "put"
          }
          params.sysCode = this.$sysCode
          this.$axios[methods](api, params)
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
              }
              this.getSysUserList()
              this.submitLoding = false
              this.dialogVisible = false
              this.resetForm()
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      this.ruleForm = createInitRuleForm()
      this.$refs.ruleForm.resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }
  ::v-deep .el-tag {
    max-width: 130px !important;
  }

  .cascader {
    width: 255px;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 255px;
    }
  }
  .disabled_user_account {
    width: 255px;
    color: #c0c4cc;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #f5f7fa;
    box-sizing: border-box;
    cursor: not-allowed;
  }
}
</style>
